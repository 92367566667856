import { React, useState } from "react";
import FormModal from "../pages/FormModal";

const Overlay = () => {
  // const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div className="h-full flex items-center justify-center flex-col text-center gap-16 bg-white bg-opacity-90 w-full">
        <div className="uppercase font-semibold leading-tight text-5xl font-['heroText']">
          We'll be <br /> back soon
        </div>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScAgXtYjAeErComon3Y36t77DGc_RD-CvNlRmOJF8tt30Qbwg/viewform?vc=0&c=0&w=1&flr=0">
          {" "}
          <button
            class="text-white bg-[#F27121] hover:bg-[#CC4E07] focus:outline-none focus:ring-4 focus:ring-[#fdebe0] font-medium rounded-md text-sm px-16 py-2 text-center"
            // onClick={() => {
            //   setOpenModal(true);
            // }}
          >
            Join the <br /> Community
          </button>
        </a>
      </div>
      {/* <div className="">
        {openModal && <FormModal closeModal={setOpenModal} />}
      </div> */}
    </>
  );
};

export default Overlay;
