import React from "react";

const Hero = () => {
  return (
    <div className="flex justify-between bg-hero bg-no-repeat h-full">
      <div className="uppercase text-4xl sm:text-5xl md:text-6xl  pl-20 md:pl-36 pt-28 md:pt-24 leading-tight font-semibold font-['heroText']">
        Multiple <br /> Brands <br /> One <br /> Location
      </div>
    </div>
  );
};

export default Hero;
