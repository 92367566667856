import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  background-color: ${(props) =>
    (props.primary && "#ffffff") || (props.secondary && "#F27121")};
  border: 1px solid
    ${(props) => (props.primary && "#CC4E07") || (props.secondary && "#F27121")};
  border-radius: 8px;
  padding: 9px 15px;
  color: ${(props) =>
    (props.primary && "#CC4E07") || (props.secondary && "#ffffff")};

  @media only screen and (min-width: 391px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1280px) {
  }
`;

export const Buttons = ({ onClick, children, ...props }) => {
  return (
    <StyledButton onClick={onClick} {...props}>
      {children}
    </StyledButton>
  );
};
