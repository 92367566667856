import React from "react";
import { Buttons } from "./Buttons";
import Logo from "./Logo";
import { GiHamburgerMenu } from "react-icons/gi";

const Header = () => {
  return (
    <div className="h-full px-12 py-2 flex items-center justify-between font-semibold text-sm">
      <Logo />
      <div className="">
        <ul className="lg:flex hidden gap-10 ">
          <li>Brands</li>
          <li>Designer Finder</li>
          <li>Feedback</li>
          <li>Demo</li>
        </ul>
      </div>
      <div className=" gap-4 lg:flex hidden">
        <Buttons primary> SignIn </Buttons>
        <Buttons secondary> Get Started </Buttons>
      </div>
      <div className="lg:hidden">
        {" "}
        <GiHamburgerMenu size={20} />
      </div>
    </div>
  );
};

export default Header;
