import React from "react";
import Inputs from "../components/Inputs";
import { MdClose } from "react-icons/md";
import styled from "styled-components";

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  top: 0;
  left: 0;
`;

const FormModal = ({ closeModal }) => {
  return (
    <>
      <Background>
        <div className="h-[50%] w-[35%] px-[52px] py-7 flex justify-center flex-col rounded-lg bg-white ">
          <div className="fixed top-32 right-96 ">
            <button onClick={() => closeModal(false)}>
              <MdClose
                size={27}
                color="white"
                className="transition-all hover:rotate-45"
              />
            </button>
          </div>
          <div className="mb-8">
            <p className="font-semibold text-xl">
              Want to become a part of our community?
            </p>
            <p className="text-xs">Leave your details below</p>
          </div>
          <div className="flex flex-col justify-center items-center gap-9">
            <Inputs placeholder={"John Doe"} props={"Full Name"} />
            <Inputs placeholder={"johndoe@gmail.com"} props={"Email Address"} />
            <button class="text-white bg-[#F27121] hover:bg-[#CC4E07] focus:outline-none focus:ring-4 focus:ring-[#fdebe0] font-medium rounded-md text-sm px-12 py-3 text-center">
              Submit
            </button>
          </div>
        </div>
      </Background>
    </>
  );
};

export default FormModal;
