import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";

const Page = () => {
  return (
    <div className="h-full overflow-hidden">
      <div className="h-[7%] shadow-custom-orange border-b-2 border-[#fdebe0]">
        {" "}
        <Header />
      </div>
      <div className="h-[93%] ">
        {" "}
        <Hero />
      </div>
    </div>
  );
};

export default Page;
