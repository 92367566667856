import "./App.css";
import Overlay from "./components/Overlay";
import Page from "./pages/Page";

function App() {
  return (
    <div className="h-screen relative">
      <Page />
      <div className="h-full w-full absolute top-0">
        <Overlay />
      </div>
    </div>
  );
}

export default App;
