import React from "react";

const Logo = () => {
  return (
    <div className="text-lg">
      Measure<span className="bg-[#F27121] text-white">Me</span>
    </div>
  );
};

export default Logo;
